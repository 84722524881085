import request from '@/utils/request';

export function fetchList(params) {
  return request({
    url: 'v1/order/list',
    method: 'get',
    params,
  });
}

export function closeOrder(data) {
  return request({
    url: 'v1/order/update/close',
    method: 'post',
    data,
  });
}

export function deliverOrder(data) {
  return request({
    url: 'v1/order/update/delivery',
    method: 'post',
    data,
  });
}

export function getOrderDetail(id) {
  return request({
    url: `v1/order/${id}/details`,
    method: 'get',
  });
}

export function boostDigitalOrder(id) {
  return request({
    url: `v1/order/${id}/boost`,
    method: 'post',
  });
}

export function confirmOrder(id) {
  return request({
    url: `v1/order/${id}/confirm`,
    method: 'post',
  });
}

export function getMultipleOrderDetails(params) {
  return request({
    url: 'v1/order/multipleDetails',
    method: 'get',
    params,
  });
}

export function updatePayment(data) {
  return request({
    url: 'v1/order/update/payment',
    method: 'post',
    data,
  });
}

export function updateStatus(data) {
  return request({
    url: 'v1/order/update/status',
    method: 'post',
    data,
  });
}

export function updateEstDelivery(data) {
  return request({
    url: 'v1/order/update/estimateDelivery',
    method: 'post',
    data,
  });
}

export function verify(data) {
  return request({
    url: 'v1/order/verify',
    method: 'post',
    data,
  });
}

export function fetchReconcileCSV(params) {
  return request({
    url: 'v1/order/reconcile.csv',
    method: 'get',
    params,
  });
}

export function fetchReconcileAllCSV(params) {
  return request({
    url: 'v1/order/reconcile_all.csv',
    method: 'get',
    params,
  });
}

export function fetchStats(params) {
  return request({
    url: 'v1/order/stats',
    method: 'get',
    params,
  });
}

export function fetchAdminStats(params) {
  return request({
    url: 'v1/order/adminStats',
    method: 'get',
    params,
  });
}
